import React from 'react';
import {Form, Input, Select, DatePicker, Button, Space, notification, Modal, Spin} from "antd";
import { MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import PageBreadcrumbs from "../../components/PageBreadcrumbs";
import PageTitle from "../../components/PageTitle";
import {API_addvote, API_getUserCategories} from "../../api/archive";
import { useState, useEffect } from 'react';

const routes = [{ label: 'Главная', path: '/' }, { label: 'Админ', path: '/admin' }, { label: 'Добавление голосования', path: '/addvote' }];

const { RangePicker } = DatePicker;

const { TextArea } = Input;

const rangeConfig = {
    rules: [
        {
            type: 'array',
            required: true,
            message: 'Please select time!',
        },
    ],
};

const AddVote = props => {
    const [loading, setLoading] = useState(false);
    const [userCategories, setUserCategories] = useState(null);
    useEffect(() => {
        API_getUserCategories ()
            .then(data => setUserCategories(data))

    }, []);
    const onFinish = values => {
        setLoading(true);
        console.log('Received values of form:', values);


        API_addvote(values)
            .then(response => {
                setLoading(true);
                response.error ?
                    notification.error({ message: response.error })
                :
                    notification.success({ message: 'Голосование добавлено' })
                    props.history.push(`/admin`)

            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false));


    };

    function info() {
        Modal.info({
            title: 'Информация',
            content: (
                <div>
                    <p>Запись добавлена.</p>
                </div>
            ),
            onOk() {
                window.location.reload();
            },
        });
    }
    return <>
        {
            userCategories && (
                (userCategories[0].userCategoriesID === 3 || userCategories[0].userCategoriesID === 2) && <Spin spinning={loading}>
                <Form
                        layout="vertical"
                        name="addvote"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="che-form">

                    <PageBreadcrumbs routes={routes} />
                    <PageTitle title="Добавить голосование (Можно добавить 25 вопросов)" />

                    <div>
                        <Form.Item
                            name="vname"
                            label="Название голосования"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="group"
                            label="Выберите группу"
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <Select>
                                <Select.Option value="1">Ученый совет СВФУ</Select.Option>
                                <Select.Option value="2">Ученый совет ГРФ</Select.Option>
                                <Select.Option value="3">Ученый совет ИЕН</Select.Option>
                                <Select.Option value="4">Диссертационный совет 24.2.396.03</Select.Option>
                                <Select.Option value="5">Диссертационный совет 24.2.396.02</Select.Option>
                                <Select.Option value="6">Диссертационный совет 24.2.396.01</Select.Option>
                                <Select.Option value="7">Диссертационный совет Д 212.306.06</Select.Option>
                                <Select.Option value="8">ИГИиПМНС СО РАН</Select.Option>
                                <Select.Option value="9">Диссертационный совет 24.2.396.04</Select.Option>
                                <Select.Option value="10">Диссертационный совет 24.2.396.05</Select.Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="date"
                            label="Выберите дату голосования" {...rangeConfig}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}>
                            <RangePicker showTime />
                        </Form.Item>
                    </div>
                    <Form.List name="detail">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }) => (

                                    <>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'fio']}
                                            fieldKey={[fieldKey, 'fio']}
                                            rules={[{ required: true, message: 'Введите ФИО' }]}
                                        >
                                            <Input placeholder="ФИО кандидата" />
                                        </Form.Item>
                                        <Form.Item
                                            {...restField}
                                            name={[name, 'dname']}
                                            fieldKey={[fieldKey, 'dname']}
                                            rules={[{ required: true, message: 'Введите вопрос' }]}
                                        >
                                            <TextArea placeholder="Введите вопрос" rows={2} />
                                        </Form.Item>
                                        {/*<Form.Item
                                            {...restField}
                                            name={[name, 'order']}
                                            fieldKey={[fieldKey, 'order']}
                                            rules={[{ required: true, message: 'Введите порядок' }]}
                                        >
                                            <Input placeholder="Порядок" />
                                        </Form.Item>*/}
                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                    </>
                                ))}
                                <Form.Item>
                                    <div>
                                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                            Добавить вопрос
                                        </Button>
                                    </div>
                                </Form.Item>
                            </>
                        )}
                    </Form.List>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Добавить
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        )}
    </>
}

export default AddVote;