import React from 'react';
import PageBreadcrumbs from "../../../../components/PageBreadcrumbs";
import {Spin, Table, Form, Button} from 'antd';
import PageTitle from "../../../../components/PageTitle";
import { useState, useEffect } from 'react';
import {API_getStatistic, API_getVotingUsers} from "../../../../api/archive";
import { RedoOutlined } from '@ant-design/icons';

const routes = [{ label: 'Главная', path: '/' }, { label: 'Админ', path: '/admin' }, { label: 'Статистика', path: '/statistic' }];

const columns = [
    {
        title: '№',
        key: 'index',
        render: (text, record, index) => index+1,
    },
    {
        title: 'ФИО',
        dataIndex: 'votingFio',
        key: 'votingFio',
    },
    {
        title: 'Вопрос',
        dataIndex: 'votingDetailName',
        key: 'votingDetailName',
    },
    {
        title: 'ЗА',
        dataIndex: 'ok',
        key: 'ok',
    },
    {
        title: 'ПРОТИВ',
        dataIndex: 'notOk',
        key: 'notOk',
    },
];

const columns2 = [
    {
        title: '№',
        key: 'index',
        render: (text, record, index) => index+1,
    },
    {
        title: 'ФИО',
        dataIndex: 'fio',
        key: 'fio',
    },
    {
        title: 'Логин',
        dataIndex: 'login',
        key: 'login',
    },
    {
        title: 'Статус',
        dataIndex: 'vote',
        key: 'vote',
    },
];

function update() {
    window.location.reload();
  }

const Statistic = (props) => {
    const id = props.match.params.id
    const [loading, setLoading] = useState(false);
    const [statistic, setStatistic] = useState([]);
    const [votes, setVotes] = useState()

    const [userStatistic, setuserStatistic] = useState([]);

    useEffect(() => {
        setVotes(JSON.parse(localStorage.getItem("votes")))
        setLoading(true)
        API_getStatistic (id)
            .then(data => {
                const statistic = Object.values(data).map((item) => (
                    {
                        key: item.ID,
                        ...item
                    }


                ))
                setStatistic(statistic)
                console.log("Вызов data", statistic)
            })
            .finally(setLoading(false));

            setLoading(true)
            API_getVotingUsers (id)
                .then(data => 
                    setuserStatistic(data)
                )
            .finally(setLoading(false));


    }, [id]);

    return <>
    <Spin spinning={loading}>
        <Form className="che-form">
            <PageBreadcrumbs routes={routes} />
            <div>

                {
                    votes && votes ? (
                        <PageTitle title={votes.name} />
                ) : (
                    <p></p>
                )
                }


            </div>
                <Button icon={<RedoOutlined />} style={{marginBottom: '20px'}} type="primary" onClick={() => update()}>
                    Обновить
                </Button>
                <PageTitle title="Статистика" />
                <Table dataSource={statistic} columns={columns} pagination={false} />
                <PageTitle title="Голосующие" />
                <Table dataSource={userStatistic} columns={columns2} pagination={false} />
        </Form>
    </Spin>
    </>
}

export default Statistic;