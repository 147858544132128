import axios from "../utils/axios";

export const API_adduser = (json) => {

    const formData = JSON.stringify(json).replace(/"\s+|\s+"/g,'"');

    /*return axios
        .post('voting/addvote', formData, { headers: { 'Content-Type': 'multipart/form-data' }})*/
    return axios({
        method: 'POST',
        url: `/voting/user/adduser`,
        data: {
            FIO: json.fio,
            Password: json.password,
            Group: parseInt(json.group),
        },
    })
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            }
            return error.response.data
        });

};