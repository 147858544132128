import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'antd'
import { NavLink} from 'react-router-dom'
import { UserOutlined, PoweroffOutlined} from '@ant-design/icons';
import logo from './../../images/logo-voting.png';


const Header = props => {
    const logout = () => {
        localStorage.removeItem('accessToken')
        props.history.push('/login')
    }
    
    return (
        <div
            style={{
                height: 100,
                background: '#FFF',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingLeft: 20,
                color: '#4C4D5A',
            }}
        >
            <p style={{ fontSize: 20 }}>
                <NavLink to="/">
                    <img alt="Тайное голосование членов Ученого совета СВФУ" src={logo}/>
                </NavLink>
            </p>

            
            <p style={{ fontSize: 16, marginRight: 20 }}>
                <UserOutlined style={{ marginRight: 10 }}/>
                    {props.login}
                <Button icon={<PoweroffOutlined />} style={{ marginLeft: 10 }} shape="circle" size="small" onClick={logout} />
            </p>
        </div>
        
    )
}
const mapStateToProps = state => {
    return {
        login: state.user.login,
    }
}

export default connect(mapStateToProps)(Header)
