import axios from "../utils/axios";
import {notification} from "antd";

export const API_getUserCategories = () => {
    let url = `voting/getusercategories`;

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getVotingArchive = () => {
    let url = `voting/getarchive`;

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getStatistic = (id) => {
    return axios.get('voting/statistic?votingId=' + id)

        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_addvote = (values) => {

    const formData = JSON.stringify(values).replace(/"\s+|\s+"/g,'"');

    /*return axios
        .post('voting/addvote', formData, { headers: { 'Content-Type': 'multipart/form-data' }})*/
    return axios({
            method: 'POST',
            url: `voting/addvote`,
            data: formData,
        })
        .then(response => {
            return response.data
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.status);
                console.log(error.response.headers);
            }
            return error.response.data
        });

};


export const API_getVotingUsers = (id) => {
    return axios.get('voting/getvotingusers?votingNameId=' + id)

        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};