import React from 'react';
import { Menu, Layout } from 'antd';
import Header from './main/Header';
import { withRouter } from 'react-router-dom';
import { Switch, Route, NavLink, Redirect } from 'react-router-dom';
import Voting from '../pages/Voting';
import Admin from '../pages/admin/Admin';
import Statistic from "../pages/admin/statistic/[id]";
import Footer from './main/Footer';
import { CheckOutlined, CrownOutlined } from '@ant-design/icons';
import VotingDetail from '../pages/VotingDetail';
import AddVote from "../pages/admin/AddVote";
import User from "../pages/user";
import {API_getUserCategories} from "../api/archive";
import { useState, useEffect } from 'react';

const Main = props => {
    const [userCategories, setUserCategories] = useState(null);
    useEffect(() => {
        API_getUserCategories ()
            .then(data => setUserCategories(data))

    }, []);
    return (
        <div className="wrapper watermarked">
            <div className="wrapper">
                <div className="content">
                    <Header history={props.history} />
                    <Layout>
                            <Menu
                                mode="horizontal"
                                activeKey={props.history.location.pathname}
                                selectedKeys={[props.history.location.pathname]}
                            >
                                <Menu.Item key="/voting">
                                    <NavLink to="/voting">
                                        <CheckOutlined /> <span>Голосование</span>
                                    </NavLink>
                                </Menu.Item>
                                {
                                    userCategories && (
                                        (userCategories[0].userCategoriesID === 3 || userCategories[0].userCategoriesID === 2) &&
                                        <Menu.Item key="/admin">
                                            <NavLink to="/admin">
                                                <CrownOutlined  style={{ color : "red" }} /> <span>Админ</span>
                                            </NavLink>
                                        </Menu.Item>
                                    )
                                }


                            </Menu>
                    </Layout>
                        <Layout style={{ padding: '0 24px 24px', background: '#FFF' }}>
                            <Route exact path="/" render={() => <Redirect to="/voting" />} />
                            <Switch>
                                <Route path="/voting" component={Voting} />
                                <Route path="/admin" component={Admin} />
                                <Route path="/addvote" component={AddVote} />
                                <Route path="/user" component={User} />
                                <Route path="/statistic/:id" component={Statistic} />
                                <Route path="/viewvoting/:id" component={VotingDetail} />
                            </Switch>
                        </Layout>

                </div>
                <Footer />
            </div>
        </div>
    );
};

export default withRouter(props => <Main {...props} />);
