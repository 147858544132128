import React from 'react';
import { Form, Input, Button, Spin } from 'antd';
import { API_login } from './../api/auth';
import { notification } from 'antd';
import { connect } from 'react-redux';
import { setUser } from '../redux/actions';
import { useState } from 'react';
import { UserOutlined, LockOutlined} from '@ant-design/icons';


const LoginForm = props => {

    const [loading, setLoading] = useState(false);

    const onFinish = values => {
        setLoading(true);
        console.log('Received values of form: ', values.username);
        API_login(values.username, values.password)
                    .then(response => {
                        props.setUser(values.username);
                        localStorage.setItem('accessToken', response.accessToken);
                        localStorage.setItem('login', values.username);
                        notification.success({ message: `Добро пожаловать ${values.username}!` });
                        props.history.push('/');
                    })
                    .catch(() => {
                        notification.error({ message: 'Пользователь с таким логином или паролем не найден' });
                    })
                    .finally(() => setLoading(false));
    };

    return (
        <Spin spinning={loading}>
        <Form onFinish={onFinish} style={{ width: 300, margin: '0 auto' }}>
            <p style={{ fontWeight: 300, textAlign: 'center', fontSize: 20, marginBottom: 10 }}>Авторизация</p>
                <Form.Item style={{ marginBottom: 7 }} name="username" rules={[{ required: true, message: 'Введите логин' }]}>
                    <Input autoFocus prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} placeholder="введите логин" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 7 }} name="password" rules={[{ required: true, message: 'Введите пароль' }]}>
                    <Input prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }}/>} type="password" placeholder="введите пароль" size="large" />
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }}>
                    <Button type="primary" htmlType="submit" block size="large">
                        Вход
                    </Button>
                </Form.Item>
        </Form>
        </Spin>
    );
};

export default connect(
    null,
    { setUser }
)(LoginForm);
