import React from 'react'

var moment = require('moment');

const currYear = moment().startOf('year').format('YYYY');

const Footer = () => (
    <div style={{ height: 50, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '##4C4D5A', flex: '0 0 auto', color: '#4C4D5A' }}>
        <p>ФГАОУ ВО Северо-Восточный федеральный университет им. М.К. Аммосова © 2020-{currYear}</p>
    </div>
)

export default Footer
