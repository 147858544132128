import React from 'react'
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Form, Button, Spin , Radio, notification, Modal} from 'antd';
import { useState, useEffect } from 'react';
import {  API_getvotingdetail, API_postvoting, API_getvotingcheck, API_getrezult } from '../api/voting';
import { CheckOutlined, RedoOutlined, CloseCircleTwoTone, CheckCircleTwoTone } from '@ant-design/icons';

const VotingDetail = (props) => {

    const [votingDetail, setvotingDetail] = useState([]);

    const [votingcheck, setvotingcheck] = useState(true);

    const [votingRezult, setvotingRezult] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        API_getvotingcheck({ votingId: props.match.params.id }).then(response => {if (response[0].cnt > 0) {setvotingcheck(false)}})
    }, [props.match.params.id]);

    useEffect(() => {
        API_getvotingdetail ({ votingId: props.match.params.id }).then(data => setvotingDetail(data));
        API_getrezult({ votingId: props.match.params.id }).then(data => setvotingRezult(data));
    }, [props.match.params.id]);

    const routes = [
    { label: 'Главная', path: '/' }, 
    { label: 'Голосование', path: `/voting` },
    { label: props.match.params.id, path: `/viewvoting/${props.match.params.id}` },
    ]

    const handleSubmit = values => {
                setLoading(true);
                const json = {
                    votingNameId: props.match.params.id,
                    votingDetail1: values.v1,
                    votingDetail2: values.v2,
                    votingDetail3: values.v3,
                    votingDetail4: values.v4,
                    votingDetail5: values.v5,
                    votingDetail6: values.v6,
                    votingDetail7: values.v7,
                    votingDetail8: values.v8,
                    votingDetail9: values.v9,
                    votingDetail10: values.v10,
                    votingDetail11: values.v11,
                    votingDetail12: values.v12,
                    votingDetail13: values.v13,
                    votingDetail14: values.v14,
                    votingDetail15: values.v15,
                    votingDetail16: values.v16,
                    votingDetail17: values.v17,
                    votingDetail18: values.v18,
                    votingDetail19: values.v19,
                    votingDetail20: values.v20,
                    votingDetail21: values.v21,
                    votingDetail22: values.v22,
                    votingDetail23: values.v23,
                    votingDetail24: values.v24,
                    votingDetail25: values.v25,
                };
                API_postvoting(json)
                    .then(response => {
                        if (response.id===1) {
                            info()
                        }
                    })
                    .catch(function(error) {
                        try {
                            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                        } catch {
                            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                        }
                        throw Error;
                    })
                    .finally(() => setLoading(false));

    };

    function info() {
        Modal.info({
          title: 'Информация',
          content: (
            <div>
              <p>Поздравляем, вы успешно проголосовали.</p>
            </div>
          ),
          onOk() {
            window.location.reload();
          },
        });
      }

    function update() {
        window.location.reload();
      }

    return (
        <>
        <Spin spinning={loading}>

            <Form className="che-form" layout='vertical' onFinish={handleSubmit}>
            
                <PageBreadcrumbs routes={routes} />
                
                { votingcheck ? 
                <div>
                    <div style={{color: '#004085', backgroundColor: '#cce5ff', borderColor: '#b8daff', position: 'relative', padding: '12px 20px', marginBottom: '16px', border: '1px solid transparent', borderRadius: '4px'}}>
                        Пожалуйста проголосуйте по каждому пункту «За» или «Против»
                    </div>
                        {votingDetail.map(votingDet => (
                            <div>
                                <p style={{fontSize: '20px'}}><b>№ {votingDet.id}.</b></p>
                                <p style={{marginLeft: '10px', fontSize: '20px'}}><b>{votingDet.fio}</b> - {votingDet.name}</p>
                                <Form.Item name={"v"+votingDet.id} rules={[{required: true, message: 'Необходимо сделать выбор по каждому пункту'}]}>
                                    <Radio.Group name="radiogroup">
                                    <Radio value={"1"}><p style={{fontSize: '20px'}}><CheckCircleTwoTone twoToneColor="#52c41a" />За</p></Radio>
                                    <Radio style={{marginLeft: '20px'}} value={"2"}><p style={{fontSize: '20px'}}><CloseCircleTwoTone twoToneColor="#eb2f96" />Против</p></Radio>
                                    </Radio.Group>
                                </Form.Item>
                             </div>
                        ))}
  
                        <Button icon={<CheckOutlined />} type="primary" htmlType="submit">
                                Голосовать
                        </Button>

                </div>  :
                <div>
                    <PageTitle title="Результаты голосования" />
                    <Button icon={<RedoOutlined />} style={{marginBottom: '20px'}} type="primary" onClick={() => update()}>
                        Обновить
                    </Button>
                    {votingRezult.map(votingRez => (
                            <div>
                                <p>№ {votingRez.orderNum}.</p>
                                <p style={{marginLeft: '10px'}}><b>{votingRez.votingFio}</b> - {votingRez.votingDetailName}</p>
                                <Form.Item>
                                    <p> За: {votingRez.ok} Против: {votingRez.notOk}</p>
                                </Form.Item>
                             </div>
                        ))}
                </div>
                }
                
            </Form>
        </Spin>
        </>
    )
}

export default VotingDetail;