import React from 'react';
import PageBreadcrumbs from "../../components/PageBreadcrumbs";
import {Table, Button, Spin, Form} from 'antd';
import {API_getUserCategories, API_getVotingArchive} from "../../api/archive";
import { useState, useEffect } from 'react';
import PageTitle from "../../components/PageTitle";

const routes = [{ label: 'Главная', path: '/' }, { label: 'Админ', path: '/admin' }];

var moment = require('moment');

const columns = [
    {
        title: 'Название',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Группа',
        dataIndex: 'gname',
        key: 'gname',
    },
    {
        title: 'Начало голосования',
        dataIndex: 'datestart',
        key: 'datestart',
    },
    {
        title: 'Конец голосования',
        dataIndex: 'dateend',
        key: 'dateend',
    },
    
    
];

const Admin = props => {
    const [loading, setLoading] = useState(false);
    const [votingArchive, setVotingArchive] = useState([]);
    const [userCategories, setUserCategories] = useState(null);

    useEffect(() => {
        setLoading(true)
        API_getVotingArchive ()
            .then(data => {
                const votingArchive = Object.values(data).map((item) => (
                    {
                        key: item.id,
                        ...item
                    }
                ))
                setVotingArchive(votingArchive)
            })
            .finally(setLoading(false));
        API_getUserCategories ()
            .then(data => setUserCategories(data))

    }, []);
    useEffect(() => {
        localStorage.setItem("votes", [])
    },[])
    const manageForm = (votingArchive) => {
        //window.open("/statistic/" + votingArchive.id)
        localStorage.setItem("stat", votingArchive.id);
        localStorage.setItem("votes", JSON.stringify(votingArchive));
        props.history.push(`/statistic/${votingArchive.id}`)
    }

    return <>

        {
            userCategories && (
                (userCategories[0].userCategoriesID === 3 || userCategories[0].userCategoriesID === 2) && <>
                <Spin spinning={loading}>
                    <Form className="che-form">
                        <PageBreadcrumbs routes={routes} />
                        
                        <PageTitle title="Администирование" />
                        <div style={{marginBottom: '20px'}}>
                            <Button style={{marginRight: '20px'}}type="primary" href="/addvote">Добавить голосование</Button>
                            <Button type="primary" href="/user">Добавить пользователя</Button>
                        </div>
                        

                        <PageTitle title="История голосований (последние 3)" />

                            <Table
                            dataSource={votingArchive}
                            columns={columns}
                            pagination={false}
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => manageForm(record), // click row
                                }
                            }}
                            />
                    </Form>
                </Spin>
                </>
            )
        }

    </>
}


export default Admin;