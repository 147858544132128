import axios from '../utils/axios';
import { notification } from 'antd';


export const API_getvoting = () => {
    let url = `voting/getvoting`;

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getvotingdetail = (json) => {
    let url = `voting/getvotingdetail?`;
    if (json) {
        if (json.votingId) url += `&votingId=${json.votingId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};


export const API_postvoting = json => {
    return axios({
        method: 'POST',
        url: `voting/postvoting`,
        data: {
            votingNameId: json.votingNameId,
            votingDetail1: json.votingDetail1,
            votingDetail2: json.votingDetail2,
            votingDetail3: json.votingDetail3,
            votingDetail4: json.votingDetail4,
            votingDetail5: json.votingDetail5,
            votingDetail6: json.votingDetail6,
            votingDetail7: json.votingDetail7,
            votingDetail8: json.votingDetail8,
            votingDetail9: json.votingDetail9,
            votingDetail10: json.votingDetail10,
            votingDetail11: json.votingDetail11,
            votingDetail12: json.votingDetail12,
            votingDetail13: json.votingDetail13,
            votingDetail14: json.votingDetail14,
            votingDetail15: json.votingDetail15,
            votingDetail16: json.votingDetail16,
            votingDetail17: json.votingDetail17,
            votingDetail18: json.votingDetail18,
            votingDetail19: json.votingDetail19,
            votingDetail20: json.votingDetail20,
            votingDetail21: json.votingDetail21,
            votingDetail22: json.votingDetail22,
            votingDetail23: json.votingDetail23,
            votingDetail24: json.votingDetail24,
            votingDetail25: json.votingDetail25,
        },
    })
    .then(function(response) {
        return response.data;
    })
    .catch(function(error) {
        try {
            notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
        } catch {
            notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
        }
    });
};

export const API_getrezult = (json) => {
    let url = `voting/getrezult?`;
    if (json) {
        if (json.votingId) url += `&votingId=${json.votingId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};

export const API_getvotingcheck = (json) => {
    let url = `voting/getvotingcheck?`;
    if (json) {
        if (json.votingId) url += `&votingId=${json.votingId}`;
    }

    return axios({
        method: 'GET',
        url: url,
    })
        .then(function(response) {
            return response.data;
        })
        .catch(function(error) {
            try {
                notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
            } catch {
                notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
            }
        });
};