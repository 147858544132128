import React from 'react';
import PageBreadcrumbs from '../components/PageBreadcrumbs';
import PageTitle from '../components/PageTitle';
import { Table, Spin, Form, Statistic, Button } from 'antd';
import { withRouter } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { API_getvoting  } from '../api/voting';
import { RedoOutlined } from '@ant-design/icons';

const routes = [{ label: 'Главная', path: '/' }, { label: 'Голосование', path: '/voting' }];

const { Countdown } = Statistic;

var moment = require('moment');

const columns = [
    {
        title: 'Выберите',
        key: 'name',
        dataIndex: 'name',
        width: '80%',
    },
    {
        title: 'Время',
        key: 'dateend',
        dataIndex: 'dateend',
        width: '20%',
        render: (value) =>
          (<Countdown title="Завершение голосования через" value={moment(value, 'YYYY-MM-DD HH:mm:ss:SSS')} format="D дней и HH:mm:ss часов" />)
    },
];

function update() {
    window.location.reload();
  }

const Voting = props => {
    const [loading, setLoading] = useState(false);
    const [voting, setvoting] = useState([]);

    useEffect(() => {
        setLoading(true)
        API_getvoting ()
        .then(data => setvoting(data))
        .finally(setLoading(false));

    }, []);
    
    return (
        <>
        <Spin spinning={loading}>
            <Form className="che-form">
                
                <PageTitle title="Голосование" />

                <PageBreadcrumbs routes={routes} />

                <div style={{color: '#004085', backgroundColor: '#cce5ff', borderColor: '#b8daff', position: 'relative', padding: '12px 20px', marginBottom: '16px', border: '1px solid transparent', borderRadius: '4px'}}>
                    В назначенное время выберите из списка в таблице тему для голосования
                </div>
                <Button icon={<RedoOutlined />} style={{marginBottom: '20px'}} type="primary" onClick={() => update()}>
                    Обновить
                </Button>
                    <Table
                        columns={columns}
                        dataSource={voting}
                        rowClassName="row-hover"
                        pagination={false}
                        onRow={votingData => {

                                return {onClick: () => props.history.push(`/viewvoting/${votingData.id}`),}

                        }}
                    />
                
            </Form>
        </Spin>
        </>
    );
};

export default withRouter(Voting);
