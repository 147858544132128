import React from 'react';
import {Form, Input, Select, Button, notification, Modal, Spin} from "antd";
import PageBreadcrumbs from "../../components/PageBreadcrumbs";
import PageTitle from "../../components/PageTitle";
import {API_getUserCategories} from "../../api/archive";
import { useState, useEffect } from 'react';
import {API_adduser} from "../../api/register";

const routes = [{ label: 'Главная', path: '/' }, { label: 'Пользователь', path: '/user' }];

const AddUser = () => {
    const [loading, setLoading] = useState(false);
    const [userCategories, setUserCategories] = useState(null);
    useEffect(() => {
        API_getUserCategories ()
            .then(data => setUserCategories(data))

    }, []);
    const onFinish = values => {
        setLoading(true);
        console.log('Received values of form:', values);


        API_adduser(values)
            .then(response => {
                setLoading(true);
                console.log('response',response)
                response.error ? notification.error({ message: 'Произошла ошибка '+response.error }) : notification.success({ message: 'Пользователь добавлен' })
            })
            .catch(function(error) {
                try {
                    notification.error({ message: `${error.response.status}. ${error.response.statusText}`, description: error.response.data.error });
                } catch {
                    notification.error({ message: `Ошибка`, description: 'Произошла ошибка при отправке запроса' });
                }
                throw Error;
            })
            .finally(() => setLoading(false));


    };

    function info() {
        Modal.info({
            title: 'Информация',
            content: (
                <div>
                    <p>Пользователь добавлен.</p>
                </div>
            ),
            onOk() {
                window.location.reload();
            },
        });
    }
    return <>
        {
            userCategories && (
                (userCategories[0].userCategoriesID === 3 || userCategories[0].userCategoriesID === 2) && <Spin spinning={loading}>
                    <Form
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 14,
                        }}
                        layout="vertical"
                        name="adduser"
                        onFinish={onFinish}
                        autoComplete="off"
                        className="che-form"
                        >
                        <PageBreadcrumbs routes={routes} />
                        <PageTitle title="Добавить пользователя" />
                        <div>
                            <Form.Item
                                name="fio"
                                label="ФИО (Полностью)"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите логин пользователя!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Пароль"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите пароль пользователя!',
                                    },
                                ]}
                            >
                                <Input.Password />
                            </Form.Item>

                            <Form.Item
                                label="Выберите группу"
                                name="group"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Выберите группу пользователя!',
                                    },
                                ]}>
                                <Select>
                                    <Select.Option value="1">Ученый совет СВФУ</Select.Option>
                                    <Select.Option value="2">Ученый совет ГРФ</Select.Option>
                                    <Select.Option value="3">Ученый совет ИЕН</Select.Option>
                                    <Select.Option value="4">Диссертационный совет 24.2.396.03</Select.Option>
                                    <Select.Option value="5">Диссертационный совет 24.2.396.02</Select.Option>
                                    <Select.Option value="6">Диссертационный совет 24.2.396.01</Select.Option>
                                    <Select.Option value="7">Диссертационный совет Д 212.306.06</Select.Option>
                                    <Select.Option value="8">ИГИиПМНС СО РАН</Select.Option>
                                    <Select.Option value="9">Диссертационный совет 24.2.396.04</Select.Option>
                                    <Select.Option value="10">Диссертационный совет 24.2.396.05</Select.Option>
                                </Select>
                            </Form.Item>
                        </div>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Добавить
                            </Button>
                        </Form.Item>
                    </Form>
                </Spin>
            )}
    </>
}

export default AddUser;